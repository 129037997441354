<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">Asosiy vositalar malumotlari</h3>
            </div>
          </div>

          <div class="card-body">
            <div>
              <b-tabs content-class="mt-3">
                <b-tab title="Umumiy malumotlar">
                  <div class="tab__wrapper mt-5">
                    <div>
                      <div class="d-flex">
                        <div class="vol text-left">
                          <h3>Asosiy Vositalar</h3>
                        </div>
                        <div class="col text-right">
                          <v-btn
                            dense
                            class="mr-2"
                            color="primary"
                            @click="reAmor"
                          >
                            Qayta amortizatsiya
                          </v-btn>
                        </div>
                      </div>
                      <div class="d-flex flex-wrap">
                        <div class="col-3">
                          <v-text-field
                            dense
                            label="Nomi"
                            disabled
                            outlined
                            v-model="getDetailsMain.name"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            outlined
                            disabled
                            dense
                            label="Inventar #"
                            v-model="getDetailsMain.inventar_number"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Reg raqam"
                            v-model="getDetailsMain.reg_number"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Kirim Sanasi"
                            :value="getDetailsMain.import_date | formatDate"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            outlined
                            dense
                            label="Muddati,Oy"
                            disabled
                            v-model="getDetailsMain.period_usage"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            outlined
                            dense
                            disabled
                            label="Tugash sanasi"
                            :value="getDetailsMain.report_end_date | formatDate"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            outlined
                            dense
                            label="Boshlang'ich narxi"
                            disabled
                            :value="getDetailsMain.starting_amount | mask"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            outlined
                            dense
                            disabled
                            label="Xisob davri boshidagi qoldiq"
                            :value="
                              getDetailsMain.report_start_date_remain | mask
                            "
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            label="Xisobot davri amortizatsiyasi"
                            outlined
                            dense
                            disabled
                            :value="
                              getDetailsMain.amortization_for_report_period
                                | mask
                            "
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            outlined
                            dense
                            label="Bugungi kundagi qoldiq"
                            disabled
                            :value="getDetailsMain.remain_amount_overall | mask"
                          >
                          </v-text-field>
                        </div>
                        <div class="col-3">
                          <v-text-field
                            outlined
                            dense
                            label="Kunlik amortizatsiya"
                            disabled
                            :value="
                              getDetailsMain.daily_amortization_amount | mask
                            "
                          >
                          </v-text-field>
                        </div>
                        <!-- <div class="col-3">
                          <label>Summa</label>

                          <v-text-field
                            outlined
                            dense
                            disabled
                            v-model="getDetailsMain.total_amount"
                          >
                          </v-text-field>
                        </div> -->
                        <!-- <div class="col-3">
                          <label>Kod</label>

                          <v-text-field
                            outlined
                            dense
                            disabled
                            v-model="getDetailsMain.code"
                          >
                          </v-text-field>
                        </div> -->
                        <div class="col-3">
                          <v-text-field
                            dense
                            label="Sklad"
                            outlined
                            disabled
                            v-model="getDetailsMain.placement_warehouse_name"
                          >
                          </v-text-field>
                        </div>

                        <div class="col-12">
                          <v-textarea
                            outlined
                            label="Izoh"
                            dense
                            :value="getDetailsMain.comment"
                          ></v-textarea>
                        </div>
                      </div>
                      <div class="mt-5">
                        <h3>Asosiy vositalar ruyhati</h3>
                        <v-data-table
                          :loading="isLoading"
                          :headers="headers"
                          :single-expand="singleExpand"
                          :expanded.sync="expanded"
                          hide-default-footer
                          no-data-text="Malumotlar kiritilmagan"
                          show-expand
                          :items="getDoc"
                          class="elevation-1"
                        >
                          <template
                            v-slot:[`item.get_doc_total.total`]="{ item }"
                          >
                            {{ item.get_doc_total.total | mask }}
                          </template>
                          <template
                            v-slot:[`item.get_doc_total.nds_summa`]="{ item }"
                          >
                            {{ item.get_doc_total.nds_summa | mask }}
                          </template>
                          <template
                            v-slot:[`item.get_doc_total.total_withnds_summa`]="{
                              item
                            }"
                          >
                            {{ item.get_doc_total.total_withnds_summa | mask }}
                          </template>
                          <template v-slot:expanded-item="{ headers, item }">
                            <td :colspan="headers.length">
                              <v-data-table
                                :headers="headersdoc"
                                hide-default-footer
                                :items="item.details"
                                class="elevation-1"
                              >
                                <template v-slot:[`item.price`]="props">
                                  {{ props.item.price | mask }}
                                </template>
                                <template v-slot:[`item.total`]="props">
                                  {{ props.item.total | mask }}
                                </template>
                                <template v-slot:[`item.nds_per_item`]="props">
                                  {{ props.item.nds_per_item | mask }}
                                </template>
                                <template v-slot:[`item.nds_summa`]="props">
                                  {{ props.item.nds_summa | mask }}
                                </template>
                                <template
                                  v-slot:[`item.total_withnds_summa`]="props"
                                >
                                  {{ props.item.total_withnds_summa | mask }}
                                </template>
                              </v-data-table>
                            </td>
                          </template>
                        </v-data-table>
                      </div>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Qayta Baholash">
                  <div class="py-5">
                    <div class="my-5">
                      <div class="d-flex">
                        <div class="vol text-left">
                          <h3>Qayta Baholanishi</h3>
                        </div>
                        <div class="col text-right mb-2">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="mr-2"
                                @click="reAmor"
                              >
                                Qayta amortizatsiya
                              </v-btn>
                            </template>
                            <span>
                              Hisobot davri uchun amortizatsiya summasini qayta
                              hisoblash
                            </span>
                          </v-tooltip>
                          <!-- <v-btn
                            small
                            class="mr-2"
                            color="primary"
                            @click="reAmor"
                          >
                            Qayta amortizatsiya
                          </v-btn> -->
                          <v-btn small color="primary" @click="modal = true">
                            Qayta Baholash
                          </v-btn>
                        </div>
                      </div>
                      <template>
                        <v-row justify="center">
                          <v-dialog
                            v-model="modal"
                            persistent
                            max-width="600px"
                          >
                            <v-card>
                              <v-card-title>
                                <span class="headline"
                                  >Asosiy vositalar qayta baholanishi</span
                                >
                              </v-card-title>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <v-text-field
                                        v-model="remark.remain_amount_overall"
                                        v-currency="options"
                                        label="new_remain_value"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <v-text-field
                                        v-model="remark.period_usage"
                                        label="new_period_usage"
                                        outlined
                                        dense
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <v-menu
                                        v-model="menu2"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            v-model="remark.oper_date"
                                            label="Oper sana"
                                            prepend-inner-icon="event"
                                            readonly
                                            outlined
                                            v-bind="attrs"
                                            dense
                                            v-on="on"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="remark.oper_date"
                                          @input="menu2 = false"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-textarea
                                        v-model="remark.comment"
                                        outlined
                                        label="Komment"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn @click="modal = false"
                                  >Bekor qilish</v-btn
                                >
                                <v-btn color="primary" @click="save"
                                  >Saqlash</v-btn
                                >
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-row>
                      </template>
                      <div class="d-flex flex-wrap">
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Kunlik amortizatsiya summasi
"
                            :value="
                              getDetailsMain.daily_amortization_amount | mask
                            "
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Barcha amortizatsiya"
                            :value="getDetailsMain.amortization_all | mask"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Umumiy miqdor"
                            :value="getDetailsMain.remain_amount_overall | mask"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Hisobot davri uchun amortizatsiya"
                            :value="
                              getDetailsMain.amortization_for_report_period
                            "
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Umumiy hisob"
                            :value="getDetailsMain.total_amount | mask"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Import sana"
                            :value="getDetailsMain.import_date | formatDate"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Boshlang'ich sana"
                            :value="
                              getDetailsMain.report_start_date | formatDate
                            "
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            label="Tugash sana"
                            :value="getDetailsMain.report_end_date | formatDate"
                          />
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            dense
                            outlined
                            disabled
                            v-currency="options"
                            label="Boshlang'ich sana (qoldik)"
                            :value="
                              getDetailsMain.report_start_date_remain | mask
                            "
                          />
                        </v-col>
                      </div>
                      <v-data-table
                        :loading="isLoading"
                        :headers="headers2"
                        no-data-text="Malumot kiritilmagan"
                        hide-default-footer
                        :items="gerRemarks"
                        class="elevation-1"
                      >
                      </v-data-table>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Swal from 'sweetalert2'
export default {
  data() {
    return {
      remark: {},
      expanded: [],
      modal: false,
      singleExpand: true,
      headersdoc: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'import_group.name',
          value: 'import_group.name'
        },
        {
          text: 'Nomenklatura nomi',
          value: 'import_category.name'
        },
        {
          text: 'Holati',
          value: 'product_condition.name'
        },
        {
          text: 'Soni',
          value: 'amount'
        },
        {
          text: 'Narxi',
          value: 'price'
        },
        {
          text: 'Jami',
          value: 'total'
        },
        {
          text: 'nds',
          value: 'nds'
        },
        {
          text: 'nds_per_item',
          value: 'nds_per_item'
        },
        {
          text: 'Nds summa',
          value: 'nds_summa'
        },
        {
          text: 'jami nds summa',
          value: 'total_withnds_summa'
        }
      ],
      headers: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'Kontragent nomi',
          value: 'contragent_name'
        },

        {
          text: 'Kontragent Shartnoma #',
          value: 'contragent_contract_number'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'reg raqami',
          value: 'reg_number'
        },
        {
          text: 'Manba',
          value: 'payment_source_description'
        },
        {
          text: 'responsible',
          value: 'responsible_name'
        },
        {
          text: 'Summa',
          value: 'get_doc_total.total'
        },
        {
          text: 'NDS summa',
          value: 'get_doc_total.nds_summa'
        },
        {
          text: 'Summa(NDS)',
          value: 'get_doc_total.total_withnds_summa'
        },
        { text: '', value: 'data-table-expand' }
      ],
      headers2: [
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'Turi nomi',
          value: 'type_name'
        },

        {
          text: 'Summasi',
          value: 'remark_value'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Komment',
          value: 'comment'
        }
      ],
      value: null,
      locale: 'default',
      selectedCurrencyOption: 1,
      currencyCode: 'EUR',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 2,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999],
      minActive: false,
      maxActive: false,
      autoDecimalMode: false,
      valueAsInteger: false,
      allowNegative: false
    }
  },
  created() {
    this.$store.dispatch('getmainassetsDetails', this.$route.params.id)
  },
  watch: {
    getDetailsMain: function (val) {
      // val.starting_amount = val.starting_amount.toLocaleString("es-US")
      this.remark.remain_amount_overall = val.remain_amount_overall
      this.remark.period_usage = val.period_usage
      console.log('remark', this.remark)
    }
  },
  computed: {
    options() {
      return {
        locale: 'es-US',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getDetails() {
      const data =
        this.$store.state.requests.mainassetsDetails.mainassetsdoc_set
      const data2 = []
      for (
        let i = 0;
        i <
        this.$store.state.requests.mainassetsDetails.mainassetsdoc_set.length;
        i++
      ) {
        data2.push(...data[i].details)
      }
      return data2
    },
    getDoc() {
      const data =
        this.$store.state.requests.mainassetsDetails.mainassetsdoc_set
      return data
    },
    gerRemarks() {
      const data =
        this.$store.state.requests.mainassetsDetails.mainassetspriceremark_set
      if (data !== undefined) {
        data.forEach((element) => {
          if (element.remark_value !== null) {
            element.remark_value = element.remark_value.toLocaleString('es-US')
          }
          if (element.type !== undefined && element.type == 'DEC') {
            element.type_name = 'Pasaytirish'
          } else {
            element.type_name = 'Oshirish'
          }
        })
      }
      return data
    },
    getDetailsMain() {
      const data = this.$store.state.requests.mainassetsDetails
      data.starting_amount = parseInt(data.starting_amount, 10)
      return data
    }
  },
  filters: {
    mask: (val) => {
      if (val !== undefined) {
        return val.toLocaleString('es-US')
      }
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Asosiy vositalar malumotlari' }
    ])
  },
  methods: {
    reAmor() {
      this.$store.dispatch('getReArmor', this.$route.params.id)
    },
    save() {
      // console.log(Object.keys(this.remark).length == 4);
      if (Object.keys(this.remark).length > 2) {
        const data = {
          new_remain_value: parseInt(
            this.remark.remain_amount_overall.replace(/[ ,.]/g, ''),
            10
          ),
          oper_date: this.remark.oper_date,
          comment: this.remark.comment,
          main_assets_id: parseInt(this.$route.params.id, 10),
          new_period_usage: parseInt(this.remark.period_usage, 10)
        }
        console.log('if', data)
        this.$store.dispatch('mainAssetsRemarksCreate', {
          data: data,
          id: this.$route.params.id
        })
        this.remark = {}
        this.modal = false
      } else {
        Swal.fire({
          title: '',
          text: "Ma'lumotlarda o'zgarish bolmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      }
    }
  }
}
</script>

<style scoped>
/* .col-3 {
  height: 50px;
  padding: 10px;
} */
.h-90 {
  height: 90%;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
}
</style>
